;var JsTemplate = function (id) {

    this.id = id;

    let templateName = '#template-' + this.id;
    this.getTemplate = function () {
        return jQuery('#search-templates').find(templateName).html();
    };

    this.content = this.getTemplate();
    this.origContent = this.content;

    this.replace = function (pattern, text) {
        try {
            if (typeof pattern === 'object') {
                for (let pttrn in pattern) {
                    this.content = this.content.replace(new RegExp('{' + pttrn + '}', 'g'), pattern[pttrn]);
                }
            } else {
                this.content = this.content.replace(new RegExp(pattern, 'g'), text);
            }
        } catch (e) {
            console.log(e.message, templateName);
        }
        return this;
    };

    this.reset = function () {
        this.content = this.origContent;
    };

    this.hideSection = function (sectionClass) {
        let content = jQuery(this.content);
        let section = content.find('section.' + sectionClass);
        section.remove();
        this.content = content.html();
    }
};